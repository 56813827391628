import React from "react";
import Styled from "styled-components";

const Email = Styled.input`
    color: #632A6D;
    font-size: 14px;
    padding: 14px 22px;
    border: 1px solid #632A6D;
    box-shadow: inset 2px 1px 4px 0 rgba(46,20,50,0.53);
    border-radius: 2px;
    margin-bottom: 8px;
    width: 396px;
    @media screen and (max-width: 480px) {
      width: 100%;
    }
`;
const SubmitBtn = Styled.button`
    font-size: 14px;
    color: white;
    background-color: #632A6D;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.39);
    border-radius: 3px;
    padding: 8px 53px;
    text-transform: uppercase;
    border: none;
    font-weight: bold;
    line-height: 20px;
    align-self: flex-end;
`;
const Container = Styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`;
const Sending = Styled.div`
    color: #632A6D;
    padding-bottom: 30px;
    text-align: center;
    `;
const Error = Styled.div`
    padding-bottom: 30px;
    text-align: center;
    color: #632A6D;
`;
const Success = Styled.div`
    color: #632A6D;
    font-weight: bold;
    padding-bottom: 30px;
    text-align: center;
`;
export const Subscribe = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value
    });

  return (
    <Container>
      {status === "sending" && <Sending>sending...</Sending>}
      {status === "error" && (
        <Error dangerouslySetInnerHTML={{ __html: message }} />
      )}
      {status === "success" && (
        <Success dangerouslySetInnerHTML={{ __html: message }} />
      )}
      {status === null && (
        <>
          <Email
            ref={node => (email = node)}
            type="email"
            placeholder="Email"
          />
          <SubmitBtn onClick={submit}>Submit</SubmitBtn>
        </>
      )}
    </Container>
  );
};
