import React from "react";
import Styled from "styled-components";
import Layout from "../components/layout";
import { Container, Title } from "../components/page-styles";
import { connect } from "react-redux";
import { fetchNews, toggleDialog } from "../store/site/actions";

import DialogBox from "../components/dialog-box";
import { docText, Twitter, Facebook, Email } from "../components/social";
import ToTopIcon from "../icons/icon-top.svg";
import SubscribeIcon from "../icons/subscribe-icon.svg";

const PostTitle = Styled.h1`
  font-size: 36px;
  line-height: 42px;
  font-weight: 200;
  margin-bottom: 20px;
`;
const Separator = Styled.hr`
  background: #C5B8CF;
  margin: 30px 0 50px;
`;
const Floating = Styled.div`
  position: fixed;
  bottom: 0;
  right: 24px;
  display: flex;
  margin-bottom: 8px;
`;
const ToTop = Styled.div`
  cursor: pointer;
  width: 41px;
  height: 41px;
  border-radius: 3px;
  background-color: #632A6D;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  letter-spacing: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.50);
  &:hover {
    background-color: #763b82;
  }
`;
const Subscribe = Styled(ToTop)`
  width: 157px;
  margin-right: 8px;
  justify-content: space-between;
  padding: 12px;
  padding-right: 0;
`;
const SubscribeText = Styled.div`
  color: white;
  flex: 1 0 auto;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
`;
const Post = Styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 988px){
      .wp-caption {
        /* Force the box to be 100% */
        width: 100% !important;
      }
      #content .wp-caption a img {
        /* Scale down if too big */
        max-width: 99.03225806%; /* 614/620 */
        height: auto;
      }
    }
    figcaption {
      color: #A28DAD;
      font-size: 90%;
      display: block;
      margin-bottom: 16px;
    }
    img {
      height: auto;
      max-width: 100%;
      margin: 0;
      border-radius: 5px;
    }
    .alignleft, .aligncenter, .alignright {
      margin-top: 8px;
      figcaption {
        text-align: center;
        font-size: 12px;
      }
    }
    .alignleft {
        float: left;
        
        margin-right: 20px;
    }
    .aligncenter {
        align-self: center;
    }
    .alignright {
        align-self: flex-end;
        margin-left: 20px;
    }
    p {
        font-family: 'Libre Baskerville';
        font-size: 16px;
        line-height: 30px;
        text-align: justify;
    }
`;
const Social = Styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 15px;
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      cursor: pointer;
      margin: 0;
      margin-left: 17px;
      padding: 0;
      &:hover svg .social-bg {
        fill: #632A6D;
      }
    }
  }
`;
const Content = Styled.div`
  display: block;
  flex-shrink: 0;
  figure {
    margin: 0;
  }
  .wp-block-image {
    text-align: center;
    flex-shrink: 0;
  }
`;
class NewsPage extends React.Component {
  constructor(props) {
    super(props);
    props.fetchNews();
    this.toTop = this.toTop.bind(this);
  }
  toTop() {
    document.querySelector("body").scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  render() {
    return (
      <Layout>
        <Container>
          <Title>News</Title>
          {this.props.isLoading ? (
            <div>Loading...</div>
          ) : (
            this.props.newsPosts.map(post => {
              return (
                <Post key={post.id} id={post.id}>
                  <meta
                    property="og:url"
                    content={"https://noblecaplanabrams.ca/news/#" + post.id}
                  />
                  <meta property="og:type" content="article" />
                  <meta
                    property="og:title"
                    content={docText(post.title.rendered)}
                  />
                  <meta
                    property="og:description"
                    content={docText(post.excerpt.rendered)}
                  />
                  <meta property="og:image" content="" />
                  <PostTitle>{post.title.rendered}</PostTitle>
                  <Social>
                    <ul>
                      <li>
                        <Email post={post} />
                      </li>
                      <li>
                        <Twitter post={post} />
                      </li>
                      <li>
                        <Facebook post={post} />
                      </li>
                    </ul>
                  </Social>
                  <Content
                    dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                  />
                  <Separator />
                </Post>
              );
            })
          )}
          <DialogBox />
          <Floating>
            <Subscribe onClick={this.props.toggleDialog}>
              <SubscribeIcon />
              <SubscribeText>Subscribe</SubscribeText>
            </Subscribe>
            <ToTop onClick={this.toTop}>
              <ToTopIcon />
            </ToTop>
          </Floating>
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = ({ site }) => {
  return {
    newsPosts: site.newsPosts,
    isLoading: site.loading,
    dialogState: site.dialogState
  };
};

const mapDispatchToProps = dispatch => ({
  fetchNews: () => dispatch(fetchNews()),
  toggleDialog: () => dispatch(toggleDialog())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsPage);
