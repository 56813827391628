import React, { Component } from "react";
import Styled from "styled-components";
import { connect } from "react-redux";
import { toggleDialog } from "../store/site/actions";
import posed from "react-pose";
import OpenMail from "../icons/open-mail.svg";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import { Subscribe } from "./subscribe";

const Header = Styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  position: relative;
`;
const CloseBtn = Styled.div`
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    div {
      background-color: #632a6d;
    }
  }
  div {
    position: absolute;
    width: 16px;
    height: 2px;
    background-color: #632a6d;
    border-radius: 2px;
    transform: rotate(-45deg);
    & + div {
      transform: rotate(45deg);
    }
  }
`;
const Container = Styled.div`
    width: 436px;
    height: 205px;
    z-index: 2001;
    background-color: #ECDCF8;
    border-radius: 4px;
    padding: 17px 19px;
`;
const Wrapper = posed.div({
  enter: {
    x: 0,
    opacity: 1
  },
  exit: {
    x: 436,
    opacity: 0
  }
});
const StyledWrapper = Styled(Wrapper)`
  z-index: 1999; 
  position: fixed;
  bottom: 61px;
  right: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
  border-radius: 3px;
  @media screen and (max-width: 480px) {
    right: 0;
    left: 0;
  }
`;
const Body = Styled.div`
  display: flex;
  padding-bottom: 19px;
  height: 100%;
`;
const Content = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;
const StyledOpenMail = Styled(OpenMail)`
  position: absolute;
  top: -54px;
  left: 50%;
  margin-left: -38px;
`;
const Copy = Styled.p`
  margin-top: 20px;
`;
const url =
  "https://noblecaplanabrams.us7.list-manage.com/subscribe/post?u=6dd8ab4fb592cf5a65b2c1930&amp;id=006aec3e48";
class DialogBox extends Component {
  render() {
    return (
      <StyledWrapper pose={this.props.dialogState ? "enter" : "exit"}>
        <Container>
          <Header>
            <div />
            <StyledOpenMail />
            <CloseBtn onClick={this.props.toggleDialog}>
              <div />
              <div />
            </CloseBtn>
          </Header>
          <Body>
            <Content>
              <Copy>Subscribe to our newsletter.</Copy>
              <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                  <Subscribe
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                  />
                )}
              />
            </Content>
          </Body>
        </Container>
      </StyledWrapper>
    );
  }
}

const mapStateToProps = ({ site }) => ({
  dialogState: site.dialogState
});

const mapDispatchToProps = dispatch => ({
  toggleDialog: () => dispatch(toggleDialog())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogBox);
