import React from "react";

import TwitterIcon from "../../src/icons/twitter-hover.svg";
import EmailIcon from "../../src/icons/mail-hover.svg";
import FacbookIcon from "../../src/icons/facebook-hover.svg";

export const docText = doc => {
  let temp = document.createElement("div");
  temp.innerHTML = doc;
  let text = temp.innerText.replace(/\[…\]/g, "...");
  return text;
};

export const Email = props => {
  const article = encodeURI(docText(props.post.excerpt.rendered));
  const title = encodeURI(docText(props.post.title.rendered));
  const email = "mailto:?subject=" + title + "&body=" + article;
  return (
    <a target="_blank" rel="noopener noreferrer" href={email}>
      <EmailIcon />
    </a>
  );
};
export const Facebook = props => {
  const url =
    "https://www.facebook.com/sharer.php?u=" +
    encodeURI("https://www.noblecaplanabrams.ca/news/#" + props.post.id);
  return (
    <a target="_blank" rel="noopener noreferrer" href={url}>
      <FacbookIcon />
    </a>
  );
};
export const Twitter = props => {
  const hashtags = "talentagency, noblecaplanabrams, nca";
  const url = "https://noblecaplanabrams.ca/news/%23" + props.post.id;
  const article = encodeURI(docText(props.post.excerpt.rendered));
  const tweet =
    "https://twitter.com/intent/tweet?text=" +
    article +
    "&url=" +
    url +
    "&hashtags=" +
    hashtags;
  return (
    <a target="_blank" rel="noopener noreferrer" href={tweet}>
      <TwitterIcon />
    </a>
  );
};
